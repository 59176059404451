.Insurance-main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.error {
  color: red;
  font-size: 12px;
}

/* Disabled button style when loading */
.Insurance-main .Insurance-page .loan-btm .form-page form .ipt button.loading {
  background-color: #A0C1E8;
  /* Lighter color to show disabled effect */
  cursor: not-allowed;
  /* Show a disabled cursor */
  opacity: 0.6;
  /* Make it look disabled */
}

/* Loader spinner */
.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.Insurance-main .Insurance-page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 7vmax;
}

.Insurance-main .Insurance-page .Insurance-Top {
  width: 100%;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.904) 35%, rgba(0, 0, 255, 0) 100%),
    url(https://pixydrops.com/insur-html/main-html/assets/images/backgrounds/page-header-bg.jpg);

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.Insurance-main .Insurance-page .Insurance-Top h3 {
  width: 95%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.Insurance-main .Insurance-page .Insurance-Top h3 span {
  width: 10%;
  height: 10px;
  border-radius: 30px;
  background-image: -webkit-linear-gradient(0deg, rgb(1, 95, 201) 0%, rgb(12, 224, 255) 100%);
}

.Insurance-main .Insurance-page .Insurance-Top h5 {
  width: 95%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 3vmax;
  font-weight: 700;
  letter-spacing: 1px;
  color: #015FC9;
}
.Insurance-main .Insurance-page .Insurance-Top h5 span {
  margin-left: 0.6vmax;
  font-weight: lighter;
  color: #000000b6;
}

.Insurance-main .Insurance-page .Insurance-Top .ins-dbe {
  width: 95%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 2vmax;
  gap: 1vmax;

}

.Insurance-main .Insurance-page .Insurance-Top .ins-dbe .dbe {
  width: 11%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1vmax;
  border-radius: 10px;
  background-position: center;
  background-size: cover;
  background-color: #015FC9;
  color: #fff;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: 0.2s;
  background-image: url(https://cdn.policyx.com/images/marketing-card-bg.png);
}

.Insurance-main .Insurance-page .Insurance-Top .ins-dbe .dbe img {
  width: 50%;
}

.Insurance-main .Insurance-page .Insurance-Top .ins-dbe .dbe small {
  margin-top: 1vmax;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.Insurance-main .Insurance-page .Insurance-Top .ins-dbe .dbe:hover{
  transform: scale(1.1);
  }

.Insurance-main .Insurance-page .Insurance-two {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2vmax;
  flex-direction: column;
  gap: 1vmax;
}

.Insurance-main .Insurance-page .Insurance-two h3 {
  font-size: 1.4vmax;
  text-transform: uppercase;

}


.Insurance-main .Insurance-page .Insurance-two  .Insurance{
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 2.5vmax;
}
.Insurance-main .Insurance-page .Insurance-two  .Insurance .ins-left{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 2vmax;
}

.Insurance-main .Insurance-page .Insurance-two  .Insurance .ins-left .l-one{
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background-color: #015FC9;
  /* background-color: rgb(249, 114, 64); */
  border-radius: 100px;
  padding: 1.5vmax 1vmax;
}
.Insurance-main .Insurance-page .Insurance-two  .Insurance .ins-left .l-one .left-l{
  width: 75%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 40px;
  color: #fff;
  padding-left: 1.3vmax;
}
.Insurance-main .Insurance-page .Insurance-two  .Insurance .ins-left .l-one .left-l h5{
 font-size: 1.2vmax;
 font-weight: 700;
 text-transform: uppercase;
 letter-spacing: 1.2px;
}
.Insurance-main .Insurance-page .Insurance-two  .Insurance .ins-left .l-one .left-l h6{
  font-size: 0.8vmax;
  font-weight: 400;
 }
 

.Insurance-main .Insurance-page .Insurance-two  .Insurance .ins-left .l-one .left-r{
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Insurance-main .Insurance-page .Insurance-two  .Insurance .ins-left .l-one .left-r img{
  width: 80%;
  background: #fff;
  padding: 1vmax;
  border-radius: 50px;
}











/* --------------------------------------------------------------------- */

.Insurance-main .Insurance-page .Insurance-three {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.Insurance-main .Insurance-page .Insurance-three {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 2vmax;
  padding: 2vmax;
  background: #f2f5f9;
}

.Insurance-main .Insurance-page .Insurance-three .three-left {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 20px;
  padding: 1vmax;
  background: #ffff;
}

.Insurance-main .Insurance-page .Insurance-three .three-left h6 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8vmax 1vmax;
  margin-top: 0.4vmax;
  border-radius: 10px;
  letter-spacing: 1px;
  box-shadow: 2px 2px 5px 2px rgb(242, 242, 242);
  font-family: 'Times New Roman', Times, serif;
}

.Insurance-main .Insurance-page .Insurance-three .three-right {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Insurance-main .Insurance-page .Insurance-three .three-right img {
  width: 90%;
  border-radius: 20px;
}

/* -------------------------------form----------------------------- */

.Insurance-main .Insurance-page .loan-btm {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffff;
  flex-direction: column;
}

.Insurance-main .Insurance-page .loan-btm h5 {
  width: 80%;
  margin-top: 1vmax;
  display: flex;
  font-weight: 700;
  align-items: center;
  text-transform: uppercase;
  justify-content: center;
  letter-spacing: 1px;
  margin-top: 2vmax;
  color: #015FC9;
}

.Insurance-main .Insurance-page .loan-btm .form-page {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1vmax;
}
 
.Insurance-main .Insurance-page .loan-btm .form-page  .form-img{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Insurance-main .Insurance-page .loan-btm .form-page  .form-img img{
  width: 80%;
}

.Insurance-main .Insurance-page .loan-btm .form-page form {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background: #f2f5f9;
  gap: 1vmax;
  margin-top: 1vmax;
  padding: 1vmax;
  border-radius: 5px;
  margin-bottom: 0vmax;
  margin-top: 1vmax

}

.Insurance-main .Insurance-page .loan-btm .form-page form .ipt {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.3vmax;
}

.Insurance-main .Insurance-page .loan-btm .form-page form .ipt label {
  font-weight: 600;
}

.Insurance-main .Insurance-page .loan-btm .form-page form .ipt input {
  width: 100%;
  padding: 0.7vmax 1vmax;
  outline: none;
  border-radius: 5px;
  border: none;
}
.Insurance-main .Insurance-page .loan-btm .form-page form .ipt textarea {
  width: 100%;
  padding: 0.7vmax 1vmax;
  outline: none;
  border-radius: 5px;
  border: none;
}

.Insurance-main .Insurance-page .loan-btm .form-page form .ipt button {
  width: 30%;
  padding: 0.8vmax 1vmax;
  border: none;
  margin-top: 1vmax;
  font-weight: 600;
  background-color: #015FC9;
  color: #ffff;
  border-radius: 5px;
  transition: 0.5s;
}

.Insurance-main .Insurance-page .loan-btm .form-page form .ipt button:hover {
  background: #1f1f1f;
}

/* ---------------------------------------------------------------------------------------------------------------- */

.Insurance-main .Insurance-page .Insurance-Index{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
}
.Insurance-main .Insurance-page .Insurance-Index  .index{
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
 margin-top: 2vmax;

}
.Insurance-main .Insurance-page .Insurance-Index  .index .index-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.Insurance-main .Insurance-page .Insurance-Index  .index .index-top small{
  width: 10%;
  height: 10px;
  border-radius: 30px;
  background-image:   -webkit-linear-gradient(0deg, rgb(1, 95, 201) 0%, rgb(12, 224, 255) 100%);
 }
.Insurance-main .Insurance-page .Insurance-Index  .index .index-top h5{
 font-size: 2vmax;
 font-weight: 600;
 letter-spacing: 1px;
 margin-top: 1vmax;
}
.Insurance-main .Insurance-page .Insurance-Index  .index .index-top h5 span{
  font-weight: 700;
  color: #015FC9;
 }
 .Insurance-main .Insurance-page .Insurance-Index  .index .index-top p{
 width: 80%;
 text-align: center;
 font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
 letter-spacing: 0.2px;
 }


 .Insurance-main .Insurance-page .Insurance-Index  .index .index-btm{
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.3vmax;
  flex-direction: row;
  margin-bottom: 2vmax;
  gap: 2vmax;
 }
 .Insurance-main .Insurance-page .Insurance-Index  .index .index-btm .ib{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 5px;
  background: #fff;
 }
 .Insurance-main .Insurance-page .Insurance-Index  .index .index-btm .ib .ib-img{
  width: 95%;
  height: 250px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  margin-top: 0.7vmax;
  border-radius: 5px;
  overflow: hidden;
cursor: pointer;
 }
 .Insurance-main .Insurance-page .Insurance-Index  .index .index-btm .ib .ib-img img{
  width: 100%;
  height: 250px;
  border-radius: 5px;
  transition: 0.5s;

 }
 .Insurance-main .Insurance-page .Insurance-Index  .index .index-btm .ib .ib-img:hover img{
 transform: scale(1.1);


 }
 .Insurance-main .Insurance-page .Insurance-Index  .index .index-btm .ib h5{
  width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    margin-top: 0.5vmax;
    color: #1f1f1fe5;
 }


  /* ------------------------------------------------------------------------- */


.Insurance-main .Insurance-page .Insurance-works{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Insurance-main .Insurance-page .Insurance-works .Work{
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2vmax;
}
.Insurance-main .Insurance-page .Insurance-works .Work .work-top{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2vmax;
}
.Insurance-main .Insurance-page .Insurance-works .Work .work-top h5{
font-size: 2.3vmax;
font-weight: 600;
}
.Insurance-main .Insurance-page .Insurance-works .Work .work-top span{
  width: 10%;
  height: 10px;
  border-radius: 30px;
  background-image:   -webkit-linear-gradient(0deg, rgb(1, 95, 201) 0%, rgb(12, 224, 255) 100%);
  margin-top: 0.6vmax;
  }
  .Insurance-main .Insurance-page .Insurance-works .Work .work-btm{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 2vmax;
    margin-bottom: 4vmax;
  }
  .Insurance-main .Insurance-page .Insurance-works .Work .work-btm       .work1{
    width: 48%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
  .Insurance-main .Insurance-page .Insurance-works .Work .work-btm  .work1 .work-left{
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Insurance-main .Insurance-page .Insurance-works .Work .work-btm  .work1 .work-left img{
    width: 100%;
  }
  .Insurance-main .Insurance-page .Insurance-works .Work .work-btm  .work1 .work-right{
    width: 70%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 1vmax;
  }
  .Insurance-main .Insurance-page .Insurance-works .Work .work-btm  .work1 .work-right h6{
    font-size: 1.2vmax;
    letter-spacing: 1px;
  }
  .Insurance-main .Insurance-page .Insurance-works .Work .work-btm  .work1 .work-right p{
   color: #1f1f1fca;
   letter-spacing: 0.3px;
  }

@media (max-width:600px) {
  .Insurance-main {
    width: 100%;
  }

  .Insurance-main .Insurance-page {
    width: 100%;
  }

  .Insurance-main .Insurance-page .Insurance-Top {
    width: 100%;
    height: 300px;
  }

  .Insurance-main .Insurance-page .Insurance-Top h3 {
    width: 95%;
  }

  .Insurance-main .Insurance-page .Insurance-Top h3 span {
    width: 30%;
  }

  .Insurance-main .Insurance-page .Insurance-Top h5 {
    width: 95%;
    font-size: 3vmax;
  }

  .Insurance-main .Insurance-page .Insurance-Top .ins-dbe {
    width: 95%;
  }
  
  .Insurance-main .Insurance-page .Insurance-Top .ins-dbe .dbe {
    width: 24%;
   font-size: 9px;
   text-align: center;
  }
  
  .Insurance-main .Insurance-page .Insurance-Top .ins-dbe .dbe img {
    width: 60%;
  }
  
  .Insurance-main .Insurance-page .Insurance-Top .ins-dbe .dbe small {
    font-weight: 400;
  }
  

  .Insurance-main .Insurance-page .Insurance-two {
    width: 100%;
  }

  .Insurance-main .Insurance-page .Insurance-two h3 {
    font-size: 3vmax;
  }

  .Insurance-main .Insurance-page .Insurance-two  .Insurance{
    width: 100%;
    flex-direction: column;
    gap:2vmax;
  }
  .Insurance-main .Insurance-page .Insurance-two  .Insurance .ins-left{
    width: 100%;
  }
  
  .Insurance-main .Insurance-page .Insurance-two  .Insurance .ins-left .l-one{
    width: 90%;
    padding: 3vmax 3vmax;
  }
  .Insurance-main .Insurance-page .Insurance-two  .Insurance .ins-left .l-one .left-l{
    width: 75%;
  }
  .Insurance-main .Insurance-page .Insurance-two  .Insurance .ins-left .l-one .left-l h5{
   font-size:2vmax;
  }
  .Insurance-main .Insurance-page .Insurance-two  .Insurance .ins-left .l-one .left-l h6{
    font-size: 1.3vmax;
   }
   
  
  .Insurance-main .Insurance-page .Insurance-two  .Insurance .ins-left .l-one .left-r{
    width: 25%;
  }
  .Insurance-main .Insurance-page .Insurance-two  .Insurance .ins-left .l-one .left-r img{
    width: 90%;
  }
  
  .Insurance-main .Insurance-page .Insurance-three {
    width: 100%;
    flex-direction: column-reverse;
  }

  .Insurance-main .Insurance-page .Insurance-three .three-left {
    width: 100%;
    border-radius: 20px;
    margin-top: 3vmax;
  }

  .Insurance-main .Insurance-page .Insurance-three .three-left h6 {
    padding: 2vmax 1vmax;
  }

  .Insurance-main .Insurance-page .Insurance-three .three-right {
    width: 100%;
  }

  .Insurance-main .Insurance-page .Insurance-three .three-right img {
    width: 100%;
  }

  /* ------------------------------------- */
  .Insurance-main .Insurance-page .loan-btm {
    width: 100%;
  }

  .Insurance-main .Insurance-page .loan-btm h5 {
    width: 95%;
    margin-top: 3vmax;
    text-align: center;
  }

  .Insurance-main .Insurance-page .loan-btm .form-page {
    width: 100%;
    flex-direction: column;
  }

  .Insurance-main .Insurance-page .loan-btm .form-page  .form-img{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Insurance-main .Insurance-page .loan-btm .form-page  .form-img img{
    width: 80%;
  }
  
  .Insurance-main .Insurance-page .loan-btm .form-page form {
    width: 95%;
    gap: 3vmax;
    padding: 2vmax;

  }

  .Insurance-main .Insurance-page .loan-btm .form-page form .ipt {
    gap: 0.7vmax;
  }


  .Insurance-main .Insurance-page .loan-btm .form-page form .ipt input {
    padding: 2vmax 1vmax;
  }

  .Insurance-main .Insurance-page .loan-btm .form-page form .ipt button {
    width: 100%;
    padding: 1.8vmax 1vmax;
  }

/* --------------------------------------------------------------------------- */

.Insurance-main .Insurance-page .Insurance-Index{
  width: 100%;
}
.Insurance-main .Insurance-page .Insurance-Index  .index{
  width: 95%;

}
.Insurance-main .Insurance-page .Insurance-Index  .index .index-top {
  width: 100%;
  
}
.Insurance-main .Insurance-page .Insurance-Index  .index .index-top small{
  width: 30%;
 
 }
.Insurance-main .Insurance-page .Insurance-Index  .index .index-top h5{
 font-size: 2.5vmax;

}

 .Insurance-main .Insurance-page .Insurance-Index  .index .index-top p{
 display: none;
 }


 .Insurance-main .Insurance-page .Insurance-Index  .index .index-btm{
  width: 95%;
  flex-direction: column;
 }
 .Insurance-main .Insurance-page .Insurance-Index  .index .index-btm .ib{
  width: 95%;
 
 }

 
  /* ------------------------------------------------------------------------- */


.Insurance-main .Insurance-page .Insurance-works{
  width: 100%;
}
.Insurance-main .Insurance-page .Insurance-works .Work{
  width: 95%;
}

.Insurance-main .Insurance-page .Insurance-works .Work .work-top h5{
font-size: 3.5vmax;
}
.Insurance-main .Insurance-page .Insurance-works .Work .work-top span{
  width: 35%;
  height: 8px;
  }
  .Insurance-main .Insurance-page .Insurance-works .Work .work-btm{
    width: 100%;
    flex-direction: column;
  }
  .Insurance-main .Insurance-page .Insurance-works .Work .work-btm       .work1{
    width: 95%;
    gap: 1vmax;
    flex-direction: column;
  }
  .Insurance-main .Insurance-page .Insurance-works .Work .work-btm  .work1 .work-left{
    width: 70%;
     margin-bottom: 3vmax;
  }
  .Insurance-main .Insurance-page .Insurance-works .Work .work-btm  .work1 .work-left img{
    width: 80%;
  }
  .Insurance-main .Insurance-page .Insurance-works .Work .work-btm  .work1 .work-right{
    width: 80%;
  }
  .Insurance-main .Insurance-page .Insurance-works .Work .work-btm  .work1 .work-right h6{
    font-size: 2.4vmax;
  }
 

}




@media (min-width:600px) and (max-width:1150px) {
  .Insurance-main {
    width: 100%;
  }

  .Insurance-main .Insurance-page {
    width: 100%;
  }

  .Insurance-main .Insurance-page .Insurance-Top {
    width: 100%;
  }

  .Insurance-main .Insurance-page .Insurance-Top h3 {
    width: 80%;
  }

  .Insurance-main .Insurance-page .Insurance-Top h3 span {
    width: 30%;
  }

  .Insurance-main .Insurance-page .Insurance-Top h5 {
    width: 80%;
    font-size: 5vmax;
  }

  .Insurance-main .Insurance-page .Insurance-Top .ins-dbe {
    width: 95%;
  }
  
  .Insurance-main .Insurance-page .Insurance-Top .ins-dbe .dbe {
    width: 24%;
   font-size: 9px;
   text-align: center;
  }
  
  .Insurance-main .Insurance-page .Insurance-Top .ins-dbe .dbe img {
    width: 60%;
  }
  
  .Insurance-main .Insurance-page .Insurance-Top .ins-dbe .dbe small {
    font-weight: 400;
  }
  
  .Insurance-main .Insurance-page .Insurance-two {
    width: 100%;
  }

  .Insurance-main .Insurance-page .Insurance-two h3 {
    font-size: 3vmax;
  }

  .Insurance-main .Insurance-page .Insurance-two  .Insurance{
    width: 100%;
    flex-direction: column;
    gap:2vmax;
  }
  .Insurance-main .Insurance-page .Insurance-two  .Insurance .ins-left{
    width: 100%;
  }
  
  .Insurance-main .Insurance-page .Insurance-two  .Insurance .ins-left .l-one{
    width: 90%;
    padding: 3vmax 3vmax;
  }
  .Insurance-main .Insurance-page .Insurance-two  .Insurance .ins-left .l-one .left-l{
    width: 75%;
  }
  .Insurance-main .Insurance-page .Insurance-two  .Insurance .ins-left .l-one .left-l h5{
   font-size:2vmax;
  }
  .Insurance-main .Insurance-page .Insurance-two  .Insurance .ins-left .l-one .left-l h6{
    font-size: 1.3vmax;
   }
   
  
  .Insurance-main .Insurance-page .Insurance-two  .Insurance .ins-left .l-one .left-r{
    width: 25%;
  }
  .Insurance-main .Insurance-page .Insurance-two  .Insurance .ins-left .l-one .left-r img{
    width: 90%;
  }
  
  
  .Insurance-main .Insurance-page .Insurance-three {
    width: 100%;
    flex-direction: column;
  }

  .Insurance-main .Insurance-page .Insurance-three .three-left {
    width: 100%;
    border-radius: 20px;
    margin-top: 3vmax;
  }

  .Insurance-main .Insurance-page .Insurance-three .three-left h6 {
    padding: 2vmax 1vmax;
  }

  .Insurance-main .Insurance-page .Insurance-three .three-right {
    width: 100%;
  }

  .Insurance-main .Insurance-page .Insurance-three .three-right img {
    width: 100%;
  }


  /* -------------------------------------- */


  .Insurance-main .Insurance-page .loan-btm {
    width: 100%;
  }

  .Insurance-main .Insurance-page .loan-btm h5 {
    width: 95%;
    margin-top: 3vmax;
    text-align: center;
  }

  .Insurance-main .Insurance-page .loan-btm .form-page {
    width: 100%;
    flex-direction: column;
  }

  .Insurance-main .Insurance-page .loan-btm .form-page  .form-img{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Insurance-main .Insurance-page .loan-btm .form-page  .form-img img{
    width: 70%;
  }

  .Insurance-main .Insurance-page .loan-btm .form-page form {
    width: 95%;
    gap: 3vmax;
    padding: 2vmax;

  }

  .Insurance-main .Insurance-page .loan-btm .form-page form .ipt {
    gap: 0.7vmax;
  }


  .Insurance-main .Insurance-page .loan-btm .form-page form .ipt input {
    padding: 2vmax 1vmax;
  }

  .Insurance-main .Insurance-page .loan-btm .form-page form .ipt button {
    width: 100%;
    padding: 1.8vmax 1vmax;
  }

/* --------------------------------------------------------------------------- */

.Insurance-main .Insurance-page .Insurance-Index{
  width: 100%;
}
.Insurance-main .Insurance-page .Insurance-Index  .index{
  width: 95%;

}
.Insurance-main .Insurance-page .Insurance-Index  .index .index-top {
  width: 100%;
  
}
.Insurance-main .Insurance-page .Insurance-Index  .index .index-top small{
  width: 30%;
 
 }
.Insurance-main .Insurance-page .Insurance-Index  .index .index-top h5{
 font-size: 2.5vmax;

}

 .Insurance-main .Insurance-page .Insurance-Index  .index .index-top p{
 width: 95%;
 }


 .Insurance-main .Insurance-page .Insurance-Index  .index .index-btm{
  width: 95%;
  flex-direction: column;
 }
 .Insurance-main .Insurance-page .Insurance-Index  .index .index-btm .ib{
  width: 95%;
 
 }

  /* ------------------------------------------------------------------------- */


  .Insurance-main .Insurance-page .Insurance-works{
    width: 100%;
  }
  .Insurance-main .Insurance-page .Insurance-works .Work{
    width: 95%;
  }
  
  .Insurance-main .Insurance-page .Insurance-works .Work .work-top h5{
  font-size: 3.5vmax;
  }
  .Insurance-main .Insurance-page .Insurance-works .Work .work-top span{
    width: 35%;
    height: 8px;
    }
    .Insurance-main .Insurance-page .Insurance-works .Work .work-btm{
      width: 100%;
      flex-direction: column;
    }
    .Insurance-main .Insurance-page .Insurance-works .Work .work-btm       .work1{
      width: 95%;
      gap: 1vmax;
      flex-direction: column;
    }
    .Insurance-main .Insurance-page .Insurance-works .Work .work-btm  .work1 .work-left{
      width: 70%;
       margin-bottom: 3vmax;
    }
    .Insurance-main .Insurance-page .Insurance-works .Work .work-btm  .work1 .work-left img{
      width: 80%;
    }
    .Insurance-main .Insurance-page .Insurance-works .Work .work-btm  .work1 .work-right{
      width: 80%;
    }
    .Insurance-main .Insurance-page .Insurance-works .Work .work-btm  .work1 .work-right h6{
      font-size: 2.4vmax;
    }
   
  
}