.Loan-main {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          /* background: #ececec; */
          background: #f2f5f9;

}

.Loan-main .loan-page {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

}

.Loan-main .loan-page .loan-top {
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          height: 500px;
          margin-top: 5vmax;
}

.Loan-main .loan-page .loan-top .loan-left {
          width: 60%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
}

.Loan-main .loan-page .loan-top .loan-left h5 {
          width: 80%;
          font-size: 2.9vmax;
          font-weight: 700;
}

.Loan-main .loan-page .loan-top .loan-left p {
          width: 80%;
          font-size: 1.2vmax;
          margin-top: 1vmax;
}

.Loan-main .loan-page .loan-top .loan-right {
          width: 40%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
}

.Loan-main .loan-page .loan-btm {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #ffff;
          flex-direction: column;
}

.Loan-main .loan-page .loan-btm h5 {
          width: 80%;
          margin-top: 1vmax;
          display: flex;
          font-weight: 700;
          align-items: center;
          text-transform: uppercase;
          justify-content: center;
          letter-spacing: 1px;
          color: #1f1f1fcb;
}

.Loan-main .loan-page .loan-btm .form-page {
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: center;
}

.Loan-main .loan-page .loan-btm .form-page form {
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          background: #f2f5f9;
          gap: 1vmax;
          margin-top: 2vmax;
          padding: 1vmax;
          border-radius: 5px;
          margin-bottom: 1vmax;
 

}

.Loan-main .loan-page .loan-btm .form-page  .form-img{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Loan-main .loan-page .loan-btm .form-page  .form-img img{
    width: 75%;
  }
  
.Loan-main .loan-page .loan-btm .form-page form .ipt {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-direction: column;
          gap: 0.3vmax;
}

.Loan-main .loan-page .loan-btm .form-page form .ipt label {
          font-weight: 600;
}

.Loan-main .loan-page .loan-btm .form-page form .ipt input {
          width: 100%;
          padding: 0.7vmax 1vmax;
          outline: none;
          border-radius: 5px;
          border: none;
}

.Loan-main .loan-page .loan-btm .form-page form .ipt button {
          width: 30%;
          padding: 0.8vmax 1vmax;
          border: none;
          margin-top: 1vmax;
          font-weight: 600;
          background-color: #015FC9;
          color: #ffff;
          border-radius: 5px;
          transition: 0.5s;
}

.Loan-main .loan-page .loan-btm .form-page form .ipt button:hover {
          background: #1f1f1f;
}
.Loan-main .loan-page .loan-btm .form-page form .ipt textarea {
    width: 100%;
    padding: 0.7vmax 1vmax;
    outline: none;
    border-radius: 5px;
    border: none;
  }


 /* ------------------------------------------------------------------------- */

 .Loan-main .loan-page .loan-counter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 3vmax;
  flex-direction: column;
  border-top: 1px solid #00000026;
  overflow: hidden; /* Ensure images stay within the section */
  z-index: 1; /* Ensure content is above background images */
}

.Loan-main .loan-page .loan-counter::before,
.Loan-main .loan-page .loan-counter::after {
  content: '';
  position: absolute;
  background-image: url('https://pixydrops.com/insur-html/main-html/assets/images/shapes/testimonial-one-shape-3.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 50vmax; /* Adjust width as needed */
  height: 80vmax; /* Adjust height as needed */
  opacity: 0.8; /* Slight transparency */
  z-index: 0; /* Ensure background images are behind everything */
  animation: subtleMove 15s ease-in-out infinite;
}

/* Left side image with a slight tilt */
.Loan-main .loan-page .loan-counter::before {
  left: -5vmax; /* Position outside the visible area */
  top: 3vmax;
  transform: rotate(-10deg); /* Slight tilt */
}

/* Right side image with a slight tilt */
.Loan-main .loan-page .loan-counter::after {
  right: -5vmax; /* Position outside the visible area */
  top: -2vmax;
  transform: rotate(10deg); /* Slight tilt */
}

/* Keyframes for subtle animation */
@keyframes subtleMove {
  0% {
      transform: translateY(0) rotate(0deg);
  }
  50% {
      transform: translateY(-10px) rotate(10deg);
  }
  100% {
      transform: translateY(0) rotate(0deg);
  }
}

/* Styling for h6 within the loan counter */
.Loan-main .loan-page .loan-counter h6 {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5vmax;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 1vmax;
  z-index: 1; /* Ensure it appears above the background */
}

/* Flexbox for loan boxes */
.Loan-main .loan-page .loan-counter .counter-page {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 1.5vmax;
  margin-bottom: 1vmax;
  z-index: 1; /* Ensure loan boxes appear above the background */
}

/* Loan box with subtle hover and scale animations */
.Loan-main .loan-page .loan-counter .counter-page .loan-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 22%;
  background-color: #ffffff26;
  padding: 2vmax;
  border-radius: 5px;
  color: #353535a0;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  z-index: 1; /* Ensure boxes are above background */
}

/* Hover effect on loan box */


/* Styling for the loan count numbers */
.Loan-main .loan-page .loan-counter .counter-page .loan-box span {
  font-size: 3vmax;
  font-weight: 700;
  z-index: 1;
}

/* Loan title styling */
.Loan-main .loan-page .loan-counter .counter-page .loan-box h6 {
  margin-top: 1vmax;
  font-size: 1vmax;
  color: #353535a0;
  z-index: 1;
}

/* Hover effect for text color change */
.Loan-main .loan-page .loan-counter .counter-page .loan-box:hover span,
.Loan-main .loan-page .loan-counter .counter-page .loan-box:hover h6 {
  color: #015ec9c8;
}




@media (max-width:600px) {
  .Loan-main {
    width: 100%;
}

.Loan-main .loan-page {
    width: 100%;

}

.Loan-main .loan-page .loan-top {
    width: 95%;
    flex-direction: column;
    margin-top: 18vmax;
}

.Loan-main .loan-page .loan-top .loan-left {
    width: 100%;
}

.Loan-main .loan-page .loan-top .loan-left h5 {
    width: 90%;
    font-size: 3vmax;
}

.Loan-main .loan-page .loan-top .loan-left p {
    width: 90%;
    font-size: 1.8vmax;
}

.Loan-main .loan-page .loan-top .loan-right {
    width: 100%;
}
.Loan-main .loan-page .loan-top .loan-right img{
  width: 85%;
}


.Loan-main .loan-page .loan-btm {
    width: 100%;
}

.Loan-main .loan-page .loan-btm h5 {
    width: 95%;
    margin-top: 3vmax;
    text-align: center;
}
.Loan-main .loan-page .loan-btm .form-page {
    width: 100%;
    flex-direction: column;
  }

  .Loan-main .loan-page .loan-btm .form-page  .form-img{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Loan-main .loan-page .loan-btm .form-page  .form-img img{
    width: 80%;
  }
  

.Loan-main .loan-page .loan-btm .form-page form {
    width: 95%;
    gap: 3vmax;
    padding: 2vmax;

}
.Loan-main .loan-page .loan-btm .form-page form .ipt {
    gap: 0.7vmax;
}


.Loan-main .loan-page .loan-btm .form-page form .ipt input {
    padding: 2vmax 1vmax;
}

.Loan-main .loan-page .loan-btm .form-page form .ipt button {
    width: 100%;
    padding: 1.8vmax 1vmax;
}



  /* ------------------------------counter--------------------------- */
  .Loan-main .loan-page .loan-counter {
    width: 100%;
    padding: 3vmax;
}

.Loan-main .loan-page .loan-counter h6 {
    width: 95%;
    font-size: 3vmax;
    text-align: center;
}

.Loan-main .loan-page .loan-counter .counter-page {
    width: 95%;
    flex-direction: column;
}

.Loan-main .loan-page .loan-counter .counter-page .loan-box {
    width: 100%;
    margin-top: 1vmax;
    padding: 2vmax;
}

/* Adjust font size for loan box span */
.Loan-main .loan-page .loan-counter .counter-page .loan-box span {
    font-size: 3vmax;
}

/* Adjust font size for loan box title */
.Loan-main .loan-page .loan-counter .counter-page .loan-box h6 {
    font-size: 2vmax;
    text-align: center;
}

/* Additional styling for images to ensure proper responsiveness */
.Loan-main .loan-page .loan-counter::before,
.Loan-main .loan-page .loan-counter::after {
    content: '';
    position: absolute;
    background-image: url('https://pixydrops.com/insur-html/main-html/assets/images/shapes/testimonial-one-shape-3.png');
    background-size: contain; /* Ensure the image fits within the container */
    background-repeat: no-repeat;
    width: 80vmax; /* Responsive width */
    height: 80vmax; /* Responsive height */
    opacity: 0.8; /* Slight transparency */
    z-index: 0; /* Keep behind content */
    animation: subtleMove 15s ease-in-out infinite;
}

/* Left side image positioning */
.Loan-main .loan-page .loan-counter::before {
    left: -10vmax; /* Responsive left positioning */
    top: 40vmax;
    transform: rotate(-10deg);
}

/* Right side image positioning */
.Loan-main .loan-page .loan-counter::after {
    right: -10vmax; /* Responsive right positioning */
    top:-5vmax;
    transform: rotate(10deg);
}
/* -------------------------------------------          */

}




@media (min-width:600px) and (max-width:1150px) {
  .Loan-main {
    width: 100%;
}

.Loan-main .loan-page {
    width: 100%;

}

.Loan-main .loan-page .loan-top {
    width: 95%;
    flex-direction: column;
    margin-top: 18vmax;
}

.Loan-main .loan-page .loan-top .loan-left {
    width: 100%;
}

.Loan-main .loan-page .loan-top .loan-left h5 {
    width: 90%;
    font-size: 3vmax;
}

.Loan-main .loan-page .loan-top .loan-left p {
    width: 90%;
    font-size: 1.8vmax;
}

.Loan-main .loan-page .loan-top .loan-right {
    width: 100%;
}
.Loan-main .loan-page .loan-top .loan-right img{
  width: 85%;
}


.Loan-main .loan-page .loan-btm {
    width: 100%;
}

.Loan-main .loan-page .loan-btm h5 {
    width: 95%;
    margin-top: 3vmax;
    text-align: center;
}
.Loan-main .loan-page .loan-btm .form-page {
    width: 100%;
    flex-direction: column;
  }

  .Loan-main .loan-page .loan-btm .form-page  .form-img{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Loan-main .loan-page .loan-btm .form-page  .form-img img{
    width: 70%;
  }
  
.Loan-main .loan-page .loan-btm .form-page form {
    width: 95%;
    gap: 3vmax;
    padding: 2vmax;

}
.Loan-main .loan-page .loan-btm .form-page form .ipt {
    gap: 0.7vmax;
}


.Loan-main .loan-page .loan-btm .form-page form .ipt input {
    padding: 2vmax 1vmax;
}

.Loan-main .loan-page .loan-btm .form-page form .ipt button {
    width: 100%;
    padding: 1.8vmax 1vmax;
}



/* Disabled button style when loading */
.Loan-main .loan-page .loan-btm .form-page form .ipt button.loading {
    background-color: #A0C1E8; /* Lighter color to show disabled effect */
    cursor: not-allowed; /* Show a disabled cursor */
    opacity: 0.6; /* Make it look disabled */
  }
  
  /* Loader spinner */
  .loader {
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #3498db;
    width: 15px;
    height: 15px;
    animation: spin 1s linear infinite;
    display: inline-block;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }



/* ------------------------------------------------------------------------- */
.Loan-main .loan-page .loan-counter {
  width: 100%;
  padding: 3vmax;
}

.Loan-main .loan-page .loan-counter h6 {
  width: 95%;
  font-size: 3vmax;
  text-align: center;
}

.Loan-main .loan-page .loan-counter .counter-page {
  width: 95%;
  flex-direction: column;
}

.Loan-main .loan-page .loan-counter .counter-page .loan-box {
  width: 100%;
  margin-top: 1vmax;
  padding: 2vmax;
}

/* Adjust font size for loan box span */
.Loan-main .loan-page .loan-counter .counter-page .loan-box span {
  font-size: 3vmax;
}

/* Adjust font size for loan box title */
.Loan-main .loan-page .loan-counter .counter-page .loan-box h6 {
  font-size: 2vmax;
}

/* Additional styling for images to ensure proper responsiveness */
.Loan-main .loan-page .loan-counter::before,
.Loan-main .loan-page .loan-counter::after {
  content: '';
  position: absolute;
  background-image: url('https://pixydrops.com/insur-html/main-html/assets/images/shapes/testimonial-one-shape-3.png');
  background-size: contain; /* Ensure the image fits within the container */
  background-repeat: no-repeat;
  width: 80vmax; /* Responsive width */
  height: 80vmax; /* Responsive height */
  opacity: 0.8; /* Slight transparency */
  z-index: 0; /* Keep behind content */
  animation: subtleMove 15s ease-in-out infinite;
}

/* Left side image positioning */
.Loan-main .loan-page .loan-counter::before {
  left: -10vmax; /* Responsive left positioning */
  top: 40vmax;
  transform: rotate(-10deg);
}

/* Right side image positioning */
.Loan-main .loan-page .loan-counter::after {
  right: -10vmax; /* Responsive right positioning */
  top:-5vmax;
  transform: rotate(10deg);
}

}
