.Whowe-main{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
}
.Whowe-main .Who-page{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
}
.Whowe-main .Who-page .W-top{
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          padding: 4vmax;
}
.Whowe-main .Who-page .W-top .w-left{
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
}
.Whowe-main .Who-page .W-top .w-left img{
       width:65%;
}
.Whowe-main .Who-page .W-top .w-right{
          width: 50%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          gap: 1vmax;
}
.Whowe-main .Who-page .W-top .w-right h6{
        font-size: 1.5vmax;
        font-weight: 700;
        letter-spacing: 1px;
}
.Whowe-main .Who-page .W-top .w-right p{
         font-family: Arial, Helvetica, sans-serif;
          letter-spacing: 1px;
  }
  /* ------------------------------------------------------    */





@media (max-width:600px) {
          .Whowe-main{
                    width: 100%;
          }
          .Whowe-main .Who-page{
                    width: 100%;
          }
          .Whowe-main .Who-page .W-top{
                    width: 95%;
                    flex-direction: column;
                    padding: 0;
                    margin-top: 1vmax;
          }
          .Whowe-main .Who-page .W-top .w-left{
                    width: 95%;
          }
          .Whowe-main .Who-page .W-top .w-left img{
                 width: 70%;
          }
          .Whowe-main .Who-page .W-top .w-right{
                    width: 90%;
                    gap: 0;
                    margin-bottom: 3vmax;
                    margin-top: 3vmax;
          }
          .Whowe-main .Who-page .W-top .w-right h6{
                  font-size: 3vmax;
          }
         
                       
}


@media (min-width:600px) and(max-width:1130px) {
          .Whowe-main{
                    width: 100%;
          }
          .Whowe-main .Who-page{
                    width: 100%;
          }
          .Whowe-main .Who-page .W-top{
                    width: 95%;
                    flex-direction: column;
                    padding: 0;
          }
          .Whowe-main .Who-page .W-top .w-left{
                    width: 95%;
          }
          .Whowe-main .Who-page .W-top .w-left img{
                 width: 95%;
          }
          .Whowe-main .Who-page .W-top .w-right{
                    width: 90%;
                    gap: 0;
                    margin-bottom: 3vmax;
          }
          .Whowe-main .Who-page .W-top .w-right h6{
                  font-size: 3vmax;
          }
         
                      
}
