.Partnership-main{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
}

.Partnership-main .partners{
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 3vmax;
}
.Partnership-main .partners h5{
          font-weight: 700;
          letter-spacing: 1px;
          width: 100%;
          display: flex;
          font-size: 1.6vmax;
          align-items: center;
          justify-content: center;
}
.Partnership-main .partners h5 span{
       color: #015FC9;
       margin-right: 0.5vmax;
}
.Partnership-main .partners .p-btm{
          width: 80%;
          display: flex;
          margin-top: 2vmax;

          align-items: center;
          justify-content: space-between;
          flex-direction: row;
}
.Partnership-main .partners .p-btm .one{
          width: 20%;
          height: 200px;
          display: flex;
          align-items: center;
          border-radius: 10px;
          justify-content: center;
          box-shadow: 2px 2px 5px 2px rgba(240, 239, 239, 0.902);
}
.Partnership-main .partners .p-btm .one img{
          width: 60%;
          height: 150px;

}


@media (max-width:600px) {
       .Partnership-main{
              width: 100%;
    }
    
    .Partnership-main .partners{
              width: 95%;
              padding: 0vmax;
    }
    .Partnership-main .partners h5{
              width: 100%;
              font-size: 3vmax;
    }
    .Partnership-main .partners .p-btm{
              width: 95%;
                gap: 3vmax;    
              flex-direction: column;
    }
    .Partnership-main .partners .p-btm .one{
              width: 100%;
              height: 200px;
              box-shadow: 2px 2px 5px 2px rgba(242, 238, 238, 0.842);
    }
    .Partnership-main .partners .p-btm .one img{
              width: 40%;
    }
    
}

@media (min-width:600px) and (max-width:1150px){
       .Partnership-main{
              width: 100%;
    }
    
    .Partnership-main .partners{
              width: 95%;
              padding: 0vmax;
    }
    .Partnership-main .partners h5{
              width: 100%;
              font-size: 3vmax;
    }
    .Partnership-main .partners .p-btm{
              width: 95%;
                gap: 3vmax;    
              flex-direction: column;
    }
    .Partnership-main .partners .p-btm .one{
              width: 100%;
              height: 200px;
              box-shadow: 2px 2px 5px 2px rgba(242, 238, 238, 0.842);
    }
    .Partnership-main .partners .p-btm .one img{
              width: 30%;
    }
              
}