.LoanWhy{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
}

.pirawhymain{
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-top: 2vmax;

      }
      .pirawhymain .pirawhycontent{
          width: 100%;
      
      }
      .pirawhymain .pirawhycontent small{
          width: 60%;
          height: 10px;
          border-radius: 30px;
          background-image:-webkit-linear-gradient(0deg, rgb(1, 95, 201) 0%, rgb(12, 224, 255) 100%);
      }
      .pirawhymain .pirawhycontent h3{
          font-size: 1.6vmax;
          font-weight: 700;
      }
      .pirawhymain .pirawhycontent p{
          width: 100%;
          font-size: 1.2vmax;
          color: rgb(51,51,51);
      }
      .pirawhyboxes{
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: 2vmax;
         
      }
      .pirawhyboxes .pirawhybox{
          width: 23%;
          margin-top: 1vmax;
          background: #fff;
          display: flex;
          border-radius: 10px;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 1vmax;
          transition: 0.3s;
      }
      .pirawhyboxes .pirawhybox:hover{
         transform: scale(1.1);
      }
      .pirawhyboxes .pirawhybox i{
          background-color: rgb(248, 243, 243);
          padding: 1.2vmax 2vmax;
          border-radius: 5vmax;
          font-size: 3vmax;
          color: #015FC9;
      }
      .pirawhyboxes .pirawhybox h5{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1vmax;
          margin-top: 1vmax;
          color: rgb(51,51,51);
          text-align: center;
      }
      
      @media (max-width:600px) {
          .pirawhymain .pirawhycontent h3{
              font-size: 3.5vmax;
          }
          .pirawhymain .pirawhycontent p{
              width: 95%;
              font-size: 1.9vmax;
          }
          .pirawhyboxes{
              flex-direction: column;
              align-items: center;
          }
          .pirawhyboxes .pirawhybox{
              width: 100%;
              margin-top: 3vmax;
              text-align: center;
          }
          .pirawhyboxes .pirawhybox i{
              padding: 2vmax 3vmax;
              font-size: 5vmax;
          }
          .pirawhyboxes .pirawhybox h5{
              width: 100%;
              font-size: 2vmax;
          }
      }
      @media (min-width:600px) and (max-width:1150px) {
          .pirawhymain .pirawhycontent h3{
              font-size: 3.5vmax;
          }
          .pirawhymain .pirawhycontent p{
              width: 95%;
              font-size: 1.9vmax;
          }
          .pirawhyboxes{
              flex-direction: column;
              align-items: center;
          }
          .pirawhyboxes .pirawhybox{
              width: 100%;
              margin-top: 3vmax;
              text-align: center;
          }
          .pirawhyboxes .pirawhybox i{
              padding: 2vmax 3vmax;
              font-size: 5vmax;
          }
          .pirawhyboxes .pirawhybox h5{
              width: 100%;
              font-size: 2vmax;
          }
      }