.OurServices{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
}
.OurServices .services-main{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          background: #f3eeee8a;
}
.OurServices .services-main .service-top{
          width: 80%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          margin-top: 2vmax;
}
.OurServices .services-main .service-top h6{
         text-transform: uppercase;
         letter-spacing: 1px;
}
.OurServices .services-main .service-top h5{
         font-size: 2.7vmax;
         font-weight: 700;
         color:#000000c0 ;

 }

 .OurServices .services-main  .service-boxes{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          margin-bottom: 2vmax;

 }
  .OurServices .services-main .service-boxes .services-btm{
          width: 80%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-direction: row;
          margin-top: 2vmax;
          gap: 2vmax;
  }
  .OurServices .services-main .service-boxes .services-btm .box {
          width: 25%;
          height: 350px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          border-radius: 10px;
          background: #fff;
          position: relative;
          cursor: pointer;
          overflow: hidden; /* Prevent overflow of overlay */
          transition: transform 0.3s; 
      }
      
      .OurServices .services-main .service-boxes .services-btm .image {
          width: 100%;
          height: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          overflow: hidden;
          position: relative; 
          transform: translate(0.5s);
      }
      
      .OurServices .services-main .service-boxes .services-btm .image img {
          width: 100%;
          height: 200px;

          transition: transform 0.3s; 
      }
      
      .OurServices .services-main .service-boxes .services-btm .box:hover img {
          transform: scale(1.1); 
      }
      
     
      .OurServices .services-main .services-btm .text {
          width: 90%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-direction: column;
      }
      
      .OurServices .services-main .service-boxes .services-btm .text .icon-box {
          width: 20%;
          height: 60px;
          border-radius: 10px;
          position: absolute;
          left: 75%;
          z-index: 111;
          bottom: 8vmax;
          display: flex;
          align-items: center;
          justify-content: center;
          transform: translate(0.5s);
          background: #fff;
          box-shadow: 0px 1px 2px 0px #d4e8ff;
      }
      .OurServices .services-main .service-boxes .services-btm .text .icon-box  .icon{
        font-size: 2.5vmax;
        font-weight: 700;
        color: #015FC9;
      }

      .OurServices .services-main .service-boxes .services-btm .box:hover .icon{
        color: #fff;
      }
      .OurServices .services-main .services-btm .box:hover .icon-box{
         background: #015FC9;
        }
      
      .OurServices .services-main .service-boxes .services-btm .text span {
          height: 5px;
          width: 20%;
          margin-top: 1vmax;
          background-image: -webkit-linear-gradient(0deg, rgb(1, 95, 201) 0%, rgb(12, 224, 255) 100%);
      }
      
      .OurServices .services-main .service-boxes .services-btm .text h6 {
          font-size: 1.3vmax;
          margin-top: 1vmax;
      }
      .OurServices .services-main .service-boxes .services-btm .box:hover h6 {
          color: #015FC9;
      }
      
      .OurServices .services-main .service-boxes .services-btm .text p {
          color: #000000ae;
          font-family: Arial, Helvetica, sans-serif;
      }



@media (max-width:600px) {
          .OurServices .services-main{
                    width: 100%;
          }
          .OurServices .services-main .service-top{
                    width: 95%;
                    margin-top: 3vmax;
          }
          .OurServices .services-main .service-top h5{
                   font-size: 4vmax;
           }
          
           .OurServices .services-main  .service-boxes{
                    width: 100%;
           }
            .OurServices .services-main .service-boxes .services-btm{
                    width: 95%;
                    flex-direction: column;
                    gap: 3vmax;
            }
            .OurServices .services-main .service-boxes .services-btm .box {
                    width: 100%;
                    box-shadow: 2px 2px 5px 2px rgba(218, 218, 218, 0.74);
                }
                
                .OurServices .services-main .service-boxes .services-btm .image {
                    width: 100%;
                    height: 200px;
                }
                .OurServices .services-main .service-boxes .services-btm .text .icon-box {
                    width: 20%;
                    height: 60px;
                    bottom: 16vmax;
                    box-shadow: 2px 2px 5px 2px rgba(226, 225, 225, 0.853);

                }
                .OurServices .services-main .service-boxes .services-btm .text .icon-box  .icon{
                  font-size: 5vmax;
                }

                .OurServices .services-main .service-boxes .services-btm .text span {
                    height: 5px;
                    width: 20%;
                    margin-top: 3vmax;
                }
              .OurServices .services-main .service-boxes .services-btm .text h6 {
                    font-size: 3vmax;
                }                      
}
    

@media (min-width:600px) and (max-width:1100px) {
    .OurServices .services-main{
              width: 100%;
    }
    .OurServices .services-main .service-top{
              width: 90%;
              margin-top: 3vmax;
    }
    .OurServices .services-main .service-top h5{
             font-size: 4vmax;
     }
    
     .OurServices .services-main  .service-boxes{
              width: 100%;
     }
     .OurServices .services-main .service-boxes .services-btm{
        width: 95%;
        flex-direction: column;
        gap: 3vmax;
}
.OurServices .services-main .service-boxes .services-btm .box {
        width: 100%;
        box-shadow: 2px 2px 5px 2px rgba(218, 218, 218, 0.74);
    }
          
          .OurServices .services-main .service-boxes .services-btm .image {
              width: 100%;
              height: 200px;
          }
          .OurServices .services-main .service-boxes .services-btm .text .icon-box {
              width: 20%;
              height: 60px;
              bottom: 14vmax;
          }
          .OurServices .services-main .service-boxes .services-btm .text .icon-box  .icon{
            font-size: 7vmax;
          }

          .OurServices .services-main .service-boxes .services-btm .text span {
              height: 5px;
              width: 20%;
              margin-top: 3vmax;
          }
        .OurServices .services-main .service-boxes .services-btm .text h6 {
              font-size: 3vmax;
          }                      
}
