.AboutUs{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
}
.AboutUs .about-main{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          background: #f3eeee8a;
}
.AboutUs .about-main .about-top{
          width: 80%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          margin-top: 1vmax;
}
.AboutUs .about-main .about-top h6{
         font-size: 1vmax;
         letter-spacing: 1px;
         text-transform: uppercase;
}

.AboutUs .about-main .about-btm{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content:center;
          background-color:#015FC9;
}
.AboutUs .about-main .about-btm .btm-abt{
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          color: #fff;
          margin-top: 1vmax;
          margin-bottom: 1vmax;
}
.AboutUs .about-main .about-btm .btm-abt .one{
          width: 20%;
          height: 250px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          text-align: center;
}
.AboutUs .about-main .about-btm .btm-abt .one h5{
        color: #ffffffda;
        font-family: 'Times New Roman', Times, serif;
}
.AboutUs .about-main .about-btm .btm-abt .one .about-icon{
          font-size: 5vmax;
          color: #fff;
          margin-bottom: 1vmax;
          font-weight: lighter
}
.AboutUs .about-main .about-btm .btm-abt .one p{
          font-size: 0.8vmax;
          color: #ffffffb1;
          letter-spacing: 1px;
          font-family: Arial, Helvetica, sans-serif;
}
.AboutUs .about-main .about-btm .btm-abt .one  button{
          border: none;
          padding: 0.5vmax 1vmax;
          border-radius: 5px;
          background-color: #0000002f;
          font-weight: 600;
        transition: 0.5s;
        color: #fff;
        font-family:Arial, Helvetica, sans-serif;
}
.AboutUs .about-main .about-btm .btm-abt .one  button:hover{
       color: #000;
       background-color: #ffffffc7;
}

@media (max-width:600px) {
    
          .AboutUs{
                    width: 100%;
          }
          .AboutUs .about-main{
                    width: 100%;

          }
          .AboutUs .about-main .about-top{
                    width: 90%;
                    margin-top: 2vmax;
          }
          .AboutUs .about-main .about-top h6{
                   font-size: 1.7vmax;
          }
        
          .AboutUs .about-main .about-btm{
                    width: 100%;
          padding-bottom: 2vmax;
          padding-top: 1vmax;

          }
          .AboutUs .about-main .about-btm .btm-abt{
                    width: 90%;
                    flex-direction: column;
                    gap: 3vmax;
          }
          .AboutUs .about-main .about-btm .btm-abt .one{
                    width: 95%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: column;
                    text-align: center;
          }
        
          .AboutUs .about-main .about-btm .btm-abt .one .about-icon{
                    font-size: 10vmax;
          }
          .AboutUs .about-main .about-btm .btm-abt .one p{
                    font-size: 2vmax;
          }
          .AboutUs .about-main .about-btm .btm-abt .one button{
                   padding: 1.3vmax 2vmax;
          }
          
          
}


@media (min-width:600px) and (max-width:1150px) {
    
          .AboutUs{
                    width: 100%;
          }
          .AboutUs .about-main{
                    width: 100%;
          }
          .AboutUs .about-main .about-top{
                    width: 90%;
                    margin-top: 2vmax;
          }
          .AboutUs .about-main .about-top h6{
                   font-size: 1.7vmax;
          }
        
          .AboutUs .about-main .about-btm{
                    width: 100%;
          padding-top: 1vmax;

          }
          .AboutUs .about-main .about-btm .btm-abt{
                    width: 90%;
                    flex-direction: column;
                    gap: 3vmax;
          }
          .AboutUs .about-main .about-btm .btm-abt .one{
                    width: 95%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: column;
                    text-align: center;
          }
        
          .AboutUs .about-main .about-btm .btm-abt .one .about-icon{
                    font-size: 10vmax;
          }
          .AboutUs .about-main .about-btm .btm-abt .one p{
                    font-size: 2vmax;
          }
          .AboutUs .about-main .about-btm .btm-abt .one button{
                   padding: 1.3vmax 2vmax;
          }
          
          
}