.NavbarMain {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1111111;
  height: 100px;
  background: #fff;
}

.navbar-main {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav {
  width: 80%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-left {
  width: 15%;
  height: 80px;
  /* background: #000; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.nav-left .logo img {
  width: 100%;
  height: 150px;
  margin-top: 2.2vmax;
  margin-bottom: 0.2vmax;

}

.nav-mid {
  width: 55%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #d4eef56b;
  border-radius: 15px;
  transition: all 0.3s ease;
}

.nav-right {
  width: 20%;
  /* background: #000; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.3vmax;
  cursor: pointer;

}

.nav-right i {
  border: 1px solid #00000071;
  padding: 0.3vmax 0.6vmax;
  border-radius: 50px;
  color: #000000ba;
  font-weight: 800;
  cursor: pointer;
}
.nav-right:hover i{
  background-color:#015FC9  ;
  border: none;
  color: #fff;
}
.nav-right:hover h5{
color: #015FC9;
}
.nav-right h5 {
  color: rgba(0, 0, 0, 0.808);
  font-weight: 600;
  margin-top: 0.5vmax;
}

.menu-toggle {
  display: none;
  cursor: pointer;
  font-size: 2rem;
  margin-bottom: 1vmax;
  color: #000;
}

@media (max-width: 768px) {
  .nav-mid {
    display: none;
    flex-direction: column;
    width: 100%;
    height: 200px;
    background: #f0f0f0;
    position: absolute;
    top: 100px;
    left: 0;
    padding: 1rem;
    border-radius:0px;
    border-top: 1px solid #ddd;
    z-index: 999;
  }
  .nav-right {
    display: none;
    flex-direction: column;
    width: 100%;
    background: #f0f0f0;
    position: absolute;
    top: 280px;
    left: 0;
    padding: 1rem;
    border-top: 1px solid #ddd;
    z-index: 999;
  }
  .nav-right i{
    padding: 0.3vmax 0.9vmax;
  }

  .nav-mid.open, .nav-right.open {
    display: flex;
  }

  .menu-toggle {
    display: block;
  }

  .nav-left {
    width: 50%;
    padding-top: 1vmax;
  }
}
