.Toastify__toast-container {
          width: 100%; /* Full width */
          display: flex;
          justify-content: center; /* Center horizontally */
          align-items: center; /* Center vertically */
          position: fixed; /* Position fixed to stay in view */
          top: 0; /* Adjust as needed */
          left: 0; /* Adjust as needed */
          z-index: 9999; /* Ensure it’s above other elements */
        }
        
        .Toastify__toast {
          margin: 0 10px; /* Add some margin if needed */
        }
        
        /* Hide scrollbar for Chrome, Safari, and Opera */
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: make scrollbar transparent */
}

/* Hide scrollbar for Firefox */
body {
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for IE and Edge */
html {
  -ms-overflow-style: none; /* IE and Edge */
}
