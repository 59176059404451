.Testimonals{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
}
.Testimonals .testimonal-main{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          background: #f2f5f9;
}
.Testimonals .testimonal-main .testimonal-top{
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          margin-top: 3vmax;
          margin-bottom: 1vmax;

}
.Testimonals .testimonal-main .testimonal-top h6{
         text-transform: uppercase;
         letter-spacing: 1px;
         font-family: 'Times New Roman', Times, serif;
}
.Testimonals .testimonal-main .testimonal-top h5{
           font-size: 2vmax;
           font-weight: 700;
           color:#000000c0 ;
           font-family: 'Times New Roman', Times, serif;
 }
 .Testimonals .testimonal-main .testimonal-btm{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 3vmax;
          margin-top: 1vmax;
}
.Testimonals .testimonal-main .testimonal-btm .testimonal-main{
          width: 80%;
          display: flex;
          align-items: center;
          justify-content:center;
          gap: 2vmax;
          flex-direction: row;
}
.Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box{
          width: 40%;
           box-shadow: 2px 2px 5px 2px rgba(128, 128, 128, 0.363);
           display: flex;
           align-items: center;
           justify-content: space-between;
           flex-direction: column;
           border-radius: 20px;
           overflow: hidden;
           background: #fff;
           padding-left: 2vmax;
           padding-bottom: 1vmax;
           height: 300px;
}
.Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box .text-top{
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
}
.Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box .text-top .test-img{
          width: 25%;
          height: 120px;
          border: 5px solid #015ec9b3;
          /* border-radius: 10%; */
          border-top-left-radius: 30px;
          overflow: hidden;
          padding: 0.2vmax;
          display: flex;
          align-items: center;
          justify-content: center;
}
.Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box .text-top .test-img img{
        width: 100%;
        height: 100px;
        border-top-left-radius: 30px;
}
.Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box .text-top .headings{
          width: 25%;
          height: 120px;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
}
.Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box .text-top .headings h5{ 
        font-size: 1.1vmax;
        font-weight: 700;
        margin-top: 2vmax;
        margin-left: 0.4vmax;
        font-family:'Times New Roman', Times, serif;
}

.Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box .text-top .headings h5 span{ 
          font-size: 1vmax;
          font-weight: 400;
          color:#015FC9;
          letter-spacing: 1px;
  }
  .Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box .text-top .headings h6{ 
          font-size: 1.4vmax;
          color: yellowgreen;
  }
  
  .Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box   .text-btm p{
          font-size: 0.9vmax;
          color: #000000bb;
          font-family: Arial, Helvetica, sans-serif;
          padding-right: 1vmax;
  }


@media (max-width:600px) {
          .Testimonals .testimonal-main{
                    width: 100%;
          }
          .Testimonals .testimonal-main .testimonal-top{
                    width: 90%;
                    display: flex;
          }
          .Testimonals .testimonal-main .testimonal-top h6{
                 font-size: 2vmax;
          }
          .Testimonals .testimonal-main .testimonal-top h5{
                     font-size: 2.3vmax;      
           }
          .Testimonals .testimonal-main .testimonal-btm .testimonal-main{
                    width: 100%;
                    flex-direction: column;
          }
          .Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box{
                    width: 90%;
          }
          .Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box .text-top{
                    width: 100%;
          }
          .Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box .text-top .test-img{
                    width: 60%;
                    height: 100px;
          }
          .Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box .text-top .test-img img{
                    width: 100%;
                    height: 110px;
          }
          .Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box .text-top .headings{
                    width: 40%;
                    height: 120px;
                    padding-left: 1vmax;
          }
          .Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box .text-top .headings h5{ 
                  font-size: 2.5vmax;
                  font-weight: 700;
                  margin-top: 2vmax;
          }
          
          .Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box .text-top .headings h5 span{ 
                    font-size: 1.3vmax;
            }
            .Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box .text-top .headings h6{ 
                    font-size: 2vmax;
            }
            .Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box .text-top .side-img{
                    width: 40%;
          }
            
            .Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box   .text-btm p{
                    font-size: 1.7vmax;
            }
                  
}


@media (min-width:600px) and (max-width:950px) {
          .Testimonals .testimonal-main{
                    width: 100%;
          }
          .Testimonals .testimonal-main .testimonal-top{
                    width: 90%;
                    display: flex;
          }
          .Testimonals .testimonal-main .testimonal-top h6{
                 font-size: 2vmax;
          }
          .Testimonals .testimonal-main .testimonal-top h5{
                     font-size: 2.3vmax;      
           }
          .Testimonals .testimonal-main .testimonal-btm .testimonal-main{
                    width: 100%;
                    flex-direction: column;
          }
          .Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box{
                    width: 90%;
          }
          .Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box .text-top{
                    width: 100%;
          }
          .Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box .text-top .test-img{
                    width: 30%;
                    height: 130px;
          }
          .Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box .text-top .test-img img{
                    width: 100%;
                    height: 120px;
          }
          .Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box .text-top .headings{
                    width: 40%;
                    height: 120px;
                    padding-left: 1vmax;
          }
          .Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box .text-top .headings h5{ 
                  font-size: 2.5vmax;
                  font-weight: 700;
                  margin-top: 2vmax;
          }
          
          .Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box .text-top .headings h5 span{ 
                    font-size: 1.3vmax;
            }
            .Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box .text-top .headings h6{ 
                    font-size: 2vmax;
            }
            /* .Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box .text-top .side-img{
                    width: 40%;
          } */
            
            .Testimonals .testimonal-main .testimonal-btm .testimonal-main .test-box   .text-btm p{
                    font-size: 1.7vmax;
            }
                  
}







