.LoanProduct{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          background: #f0fcff6b;
}

.piramain{
          width: 80%;
          
      }
      .piramain .pirah2 {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-top: 1.4vmax;

      }
      .piramain .pirah2 small{
          width: 12%;
          height: 12px;
          margin-top: 1vmax;
          border-radius: 30px;
          background-image:   -webkit-linear-gradient(0deg, rgb(1, 95, 201) 0%, rgb(12, 224, 255) 100%);
      }
      .piramain .pirah2 h2{
          font-size: 1.5vmax;
          font-weight:700;
          margin-top: 1vmax;
         letter-spacing: 1px;

         
      }
      .piracard{
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-top:1vmax;
          padding-bottom: 2.5vmax;
      }
      .piracard .pirabox{
          width: 30%;
          /* background-color: rgb(241, 147, 7); */
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1.5vmax;
          margin-top: 2vmax;
          border-radius: 0.8vmax;
          background: #fff;
          /* box-shadow: 2px 2px 5px 2px rgb(241, 241, 241); */
          transition: 0.5s;
      }
      .piracard .pirabox h5{
          font-size: 1.3vmax;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          width: 60%;
      }
      .piracard .pirabox i{
          padding: 1vmax 1.5vmax;
          background-color: rgb(212, 255, 255);
          border-radius: 5vmax;
          font-size: 2.2vmax;
          color: #015FC9;
          width: 25%;
          display: flex;
          align-items: center;
          justify-content: center;

      }
   
      
      @media (max-width:600px) {

          .LoanProduct{
                    width: 100%;
          }
          
          .piramain{
                    width: 90%;
                    
          }
          .piramain .pirah2 {
                    width: 100%;
                }
                .piramain .pirah2 small{
                    width: 35%;
                    height: 10px;
                }
          .piramain .pirah2 h2{
              font-size: 3vmax;
          }
          .piracard{
              flex-direction: column;
              align-items: center;
          }
          .piracard .pirabox{
              width: 100%;
              padding: 3vmax;
          }
          .piracard .pirabox h5{
              font-size: 2.2vmax;
          }
          .piracard .pirabox i{
              font-size: 5vmax;
          }
          
      }
        
      @media (min-width:600px) and (max-width:1150px) {

          .LoanProduct{
                    width: 100%;
          }
          
          .piramain{
                    width: 90%;
                    
          }
          .piramain .pirah2 {
                    width: 100%;
                }
                .piramain .pirah2 small{
                    width: 35%;
                    height: 10px;
                }
          .piramain .pirah2 h2{
              font-size: 3vmax;
          }
          .piracard{
              flex-direction: column;
              align-items: center;
          }
          .piracard .pirabox{
              width: 100%;
              padding: 3vmax;
          }
          .piracard .pirabox h5{
              font-size: 2.2vmax;
          }
          .piracard .pirabox i{
              font-size: 5vmax;
          }
          
      }