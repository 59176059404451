.Faq {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
}

.Faq .faq-top {
          width: 80%;
          padding: 1.5vmax;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
}

.Faq .faq-top img {
          width: 5%;
}

.Faq .faq-top h5 {
          font-weight: 700;
          font-size: 1.7vmax;
          color: #000000b3;
}

.Faq .faqq {
          width: 80%;
          display: flex;
          background-color: white;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-bottom: 2vmax;
          /* box-shadow: 2px 2px 5px 2px rgba(255, 224, 224, 0.555); */
}

.Faq .faqq .f-btm {
          margin-top: 0.5vmax;
          width: 95%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
}

.Faq .faqq .f-btm .line {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          margin-top: 2vmax;
          border-bottom: 1px solid #0000003b;
}

.Faq .faqq .f-btm .line .one {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          cursor: pointer;
}

.Faq .faqq .f-btm .line .one p {
          font-weight: 600;
}

.Faq .faqq .f-btm .line .one i {
          font-size: 1.7vmax;
          cursor: pointer;
}

.Faq .faqq .f-btm .line .two {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          color: #00b7eb;
          overflow: hidden;
          max-height: 0;
          transition: max-height 1s ease;
          padding: 0;
}

.Faq .faqq .f-btm .line .two.show {
          max-height: 500px;
          padding: 1vmax 0;
}

@media (max-width: 600px) {
          .Faq .faqq {
                    width: 95%;
          }

          .Faq .faq-top {
                    width: 95%;
          }

          .Faq .faq-top img {
                    width: 12%;
          }

          .Faq .faq-top h5 {
                    font-size: 3vmax;
          }

          .Faq .faqq .f-btm {
                    margin-top: 1.3vmax;
          }

          .Faq .faqq .f-btm .line {
                    margin-top: 3vmax;
          }

          .Faq .faqq .f-btm .line .one i {
                    font-size: 2.6vmax;
          }
}

@media (min-width: 600px) and (max-width: 1250px) {
          .Faq .faqq {
                    width: 95%;
          }

          .Faq .faq-top {
                    width: 95%;
          }

          .Faq .faq-top img {
                    width: 12%;
          }

          .Faq .faq-top h5 {
                    font-size: 3vmax;
          }

          .Faq .faqq .f-btm {
                    margin-top: 1.3vmax;
          }

          .Faq .faqq .f-btm .line {
                    margin-top: 3vmax;
          }

          .Faq .faqq .f-btm .line .one i {
                    font-size: 2.6vmax;
          }
}