.Home-page {
  width: 100%;
  height: 80vh; /* Adjust height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.968) 35%, rgba(0, 0, 255, 0) 100%), 
              url(https://media.istockphoto.com/id/1629526439/photo/the-real-estate-agent-forms-a-home-with-the-agent-and-the-client-discusses-the-contract-to.jpg?s=612x612&w=0&k=20&c=4EIq3J5mpTU102rLHHrgoIft4a9TH2PxgMvRD6lRtYQ=);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

  .background-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0; /* Behind the main content */
  }
  
  .bg-shape {
    position: absolute;
    opacity: 0.7;
    animation: float 6s ease-in-out infinite;
  }
  
  .bg-shape-2 {
    top: 35%;
    left: 0;
    width: 780px;
    animation-duration: 7s;
  }
  .bg-shape-1 {
    top: 0;
    right: 0;
    width: 50%;
    height: 580px;
    animation-duration: 7s;
  }
  
 
  @keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px); /* Adjust for the floating effect */
    }
  }
  
   
.Home-page .home-main{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row; 
    /* margin-bottom: 1vmax; */
    position: relative; /* Ensure it's above the background */
    z-index: 1;
}
.Home-page .home-main .left-main{
    width: 35%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column; 
    margin-top: 9vmax;
}
.Home-page .home-main .left-main small{
    width: 60%;
    height: 12px;
    border-radius: 30px;
    background-image:   -webkit-linear-gradient(0deg, rgb(1, 95, 201) 0%, rgb(12, 224, 255) 100%);
}
.Home-page .home-main .left-main  h5{
   font-size: 4.6vmax;
   font-weight: 500;
}
.Home-page .home-main .left-main  span{
   color: #015FC9;
   font-weight: 700;
 }
.Home-page .home-main .left-main  p{
  color: rgba(0, 0, 0, 0.699);
  margin-top: 1vmax;
  font-size: 1.1vmax;
 }
 .Home-page .home-main .left-main  button{
   margin-top: 1vmax;
   border: none;
   padding: 1.3vmax 3vmax;
   border-radius: 10px;
   font-weight: 500;
   color: #fff;
   background: #015FC9;
   transition: 0.5s;
   }
   .Home-page .home-main .left-main  button:hover{
    background: #1f1f1f;
    }  

.Home-page .home-main .right-main{
    width: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    /* margin-top: 5vmax; */
}
.Home-page .home-main .right-main img {
    width: 100%; 
}



@media (max-width:600px) {
    .Home-page {
        width: 100%;  
      } 
      .bg-shape-2 {
        top: 20%;
      }
      .bg-shape-1 {
        display: none;
      }
       
    .Home-page .home-main{
        width: 95%;
    }
    .Home-page .home-main .left-main{
        width: 80%;
        margin-top: 15vmax;
    }
    .Home-page .home-main .left-main small{
        width: 50%;
    }
    .Home-page .home-main .left-main  h5{
       font-size: 5.5vmax;
       font-weight: 700
    }
    .Home-page .home-main .left-main  p{
      font-size: 2vmax;
      width: 100%;

    }
     .Home-page .home-main .left-main  button{
       margin-top: 1vmax;
       padding: 2vmax 5vmax;
       }  
    
    .Home-page .home-main .right-main{
        width: 0%;
        display: none;
    }
   
      
}


@media (min-width:600px) and (max-width:1120px) {
    .Home-page {
        width: 100%;  
      } 
      .bg-shape-2 {
        top: 20%;
      }
      .bg-shape-1 {
        display: none;
      }
       
    .Home-page .home-main{
        width: 95%;
    }
    .Home-page .home-main .left-main{
        width: 80%;
        margin-top: 15vmax;
    }
    .Home-page .home-main .left-main small{
        width: 50%;
    }
    .Home-page .home-main .left-main  h5{
       font-size: 5.5vmax;
       font-weight: 700
    }
    .Home-page .home-main .left-main  p{
      font-size: 2vmax;
     }
     .Home-page .home-main .left-main  button{
       margin-top: 1vmax;
       padding: 2vmax 5vmax;
       }  
    
    .Home-page .home-main .right-main{
        width: 0%;
        display: none;
    }
   
      
}





  