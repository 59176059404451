.Demat-main {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid #bfbfbf;
}

.Demat-main .Demat-page {
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-bottom: 4vmax;
}

.Demat-main .Demat-page .advantage {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-top: 2vmax;
}

.Demat-main .Demat-page .advantage h5 {
          font-size: 1.6vmax;
          font-weight: 600;
          letter-spacing: 1px;
}

.Demat-main .Demat-page .advantage-slider {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 2vmax;
          cursor: grab;
          user-select: none;
}
.Demat-main .Demat-page .advantage-slider.dragging {
          user-select: none; /* Prevent text selection during dragging */
      }
.Demat-main .Demat-page .advantage-slider:active {
          cursor: grabbing;
      }
.Review-container {
          display: flex;
          gap: 2vmax;
          justify-content: space-between;
          width: 100%;
}

.Demat-main .Demat-page .advantage-slider .Review {
          width: 30%;
          display: flex;
          align-items: center;
          padding: 2vmax;
          gap: 1vmax;
          border-radius: 10px;
          justify-content: space-between;
          flex-direction: column;
          box-shadow: 2px 2px 5px 2px rgb(235, 233, 233);
}

.Demat-main .Demat-page .advantage-slider .Review h5 {
          font-weight: 600;
          font-size: 1.1vmax;
          text-transform: capitalize;
          font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.Demat-main .Demat-page .advantage-slider .Review p {
          text-align: center;
          margin-bottom: 4vmax;
}

.Demat-main .Demat-page .side-btm {
          width: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
}

.Demat-main .Demat-page .side-btm .nav-button {
          background-color: transparent;
          border: none;
          font-size: 2rem;
          cursor: pointer;
          padding: 0 1rem;
          color: #015FC9;
}


.pagination {
          display: flex;
          justify-content: center;
          margin-top: 1rem;
}

.dot {
          height: 10px;
          width: 10px;
          margin: 0 5px;
          background-color: #bbb;
          border-radius: 50%;
          display: inline-block;
          cursor: pointer;
}

.dot.active {
          background-color: #717171;
}



@media (max-width:600px) {
          .Demat-main {
                    width: 100%;
          }

          .Demat-main .Demat-page {
                    width: 95%;
          }

          .Demat-main .Demat-page .advantage {
                    width: 100%;
          }

          .Demat-main .Demat-page .advantage h5 {
                    font-size: 2vmax;
          }


          .Demat-main .Demat-page .advantage-slider .Review {
                    width: 100%;
                    height: 200px;
                    display: flex;
                    align-items: center;
                    padding: 2vmax;
                    gap: 1vmax;
                    justify-content: space-between;
                    flex-direction: column;
                    box-shadow: 2px 2px 5px 2px rgb(235, 233, 233);
          }

          .Demat-main .Demat-page .advantage-slider .Review h5 {
                    font-size: 2vmax;
                    text-align: center;
          }

          .Demat-main .Demat-page .advantage-slider .Review p {
                    text-align: center;
          }

         
          .dot.active {
                    background-color: #717171;
          }


}



@media (min-width:600px) and (max-width:950px) {
          .Demat-main {
                    width: 100%;
          }

          .Demat-main .Demat-page {
                    width: 95%;
          }

          .Demat-main .Demat-page .advantage {
                    width: 100%;
          }

          .Demat-main .Demat-page .advantage h5 {
                    font-size: 2vmax;
          }


          .Demat-main .Demat-page .advantage-slider .Review {
                    width: 100%;
                    height: 200px;
                    display: flex;
                    align-items: center;
                    padding: 2vmax;
                    gap: 1vmax;
                    justify-content: space-between;
                    flex-direction: column;
                    box-shadow: 2px 2px 5px 2px rgb(235, 233, 233);
          }

          .Demat-main .Demat-page .advantage-slider .Review h5 {
                    font-size: 2vmax;
                    text-align: center;
          }

          .Demat-main .Demat-page .advantage-slider .Review p {
                    text-align: center;
          }

         
          .dot.active {
                    background-color: #717171;
          }


}