.TradingDemat-main {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
}

.TradingDemat-main .TradingDemat-page {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          margin-top: 5vmax;
}

.TradingDemat-main .TradingDemat-page .demat-top {
          width: 100%;
          height: 350px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          background: linear-gradient(to right, rgba(255, 255, 255, 0.904) 35%, rgba(0, 0, 255, 0) 100%),
                    url(https://pixydrops.com/insur-html/main-html/assets/images/backgrounds/page-header-bg.jpg);

          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
}


.TradingDemat-main .TradingDemat-page .demat-top h3 {
          width: 80%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
}

.TradingDemat-main .TradingDemat-page .demat-top h3 span {
          width: 10%;
          height: 10px;
          border-radius: 30px;
          background-image: -webkit-linear-gradient(0deg, rgb(1, 95, 201) 0%, rgb(12, 224, 255) 100%);
}

.TradingDemat-main .TradingDemat-page .demat-top h5 {
          width: 80%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          font-size: 3vmax;
          font-weight: 700;
          letter-spacing: 1px;
}





.TradingDemat-main .TradingDemat-page .loan-btm {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #ffff;
          flex-direction: column;
}

.TradingDemat-main .TradingDemat-page .loan-btm h5 {
          width: 80%;
          margin-top: 1vmax;
          display: flex;
          font-weight: 700;
          align-items: center;
          text-transform: capitalize;
          justify-content: center;
          letter-spacing: 1px;
}

.TradingDemat-main .TradingDemat-page .loan-btm .form-page {
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: center;
}


.TradingDemat-main .TradingDemat-page .loan-btm .form-page  .form-img{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .TradingDemat-main .TradingDemat-page .loan-btm .form-page  .form-img img{
    width: 75%;
  }

.TradingDemat-main .TradingDemat-page .loan-btm .form-page form {
          width: 45%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          background: #f2f5f9;
          gap: 1vmax;
          margin-top: 1vmax;
          padding: 1vmax;
          border-radius: 5px;
          margin-bottom: 2vmax;

}

.TradingDemat-main .TradingDemat-page .loan-btm .form-page form .ipt {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-direction: column;
          gap: 0.3vmax;
}

.TradingDemat-main .TradingDemat-page .loan-btm .form-page form .ipt label {
          font-weight: 600;
}

.TradingDemat-main .TradingDemat-page .loan-btm .form-page form .ipt input {
          width: 100%;
          padding: 0.7vmax 1vmax;
          outline: none;
          border-radius: 5px;
          border: none;
}
.TradingDemat-main .TradingDemat-page  .form-page form .ipt textarea {
    width: 100%;
    padding: 0.7vmax 1vmax;
    outline: none;
    border-radius: 5px;
    border: none;
  }
.TradingDemat-main .TradingDemat-page .loan-btm .form-page form .ipt button {
          width: 30%;
          padding: 0.8vmax 1vmax;
          border: none;
          margin-top: 1vmax;
          font-weight: 600;
          background-color: #015FC9;
          color: #ffff;
          border-radius: 5px;
          transition: 0.5s;
}

.TradingDemat-main .TradingDemat-page .loan-btm .form-page form .ipt button:hover {
          background: #1f1f1f;
}



.TradingDemat-main .TradingDemat-page .demat-one {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #f2f5f9;

}

.TradingDemat-main .TradingDemat-page .demat-one .demat-content {
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          margin-top: 3vmax;
          margin-bottom: 3vmax;

}
.TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-left {
    width: 35%;
    height: 400px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-left: 2vmax;
    padding-top: 4vmax;
    
    /* Background settings */
    background: linear-gradient(to right, rgba(255, 255, 255, 0.838) 35%, rgba(0, 0, 255, 0) 100%),
                url(https://cdni.iconscout.com/illustration/premium/thumb/bank-insurance-8855593-7233537.png);
    background-position: center;
    background-size: contain; /* Use contain to fit the image without cutting */
    background-repeat: no-repeat;
    overflow: hidden;
  }
  

.TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-left span {
          width: 20%;
          height: 12px;
          border-radius: 30px;
          background-image: -webkit-linear-gradient(0deg, rgb(1, 95, 201) 0%, rgb(12, 224, 255) 100%);
}

.TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-left h5 {
          font-size: 2.6vmax;
}

.TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-left h5 small {
          color: #015FC9;
          font-weight: 700;
}

/* .TradingDemat-main .TradingDemat-page .demat-one .demat-content  .d-left img{
          width: 60%;
 } */


.TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-right {
          width: 60%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 1vmax;
}

.TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-right .dOne {
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          border-radius: 10px;
          border: 3px solid #aad2ff;
          padding: 0.5vmax;
}

.TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-right .dOne .rt {
          width: 80%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
}

.TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-right .dOne .rt h6 {
          font-weight: 700;
          color: #015ec9d9;
}

.TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-right .dOne .rt p {
          font-size: 1.1vmax;
          font-family: Arial, Helvetica, sans-serif;
}

.TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-right .dOne .lt {
          width: 20%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
}

.TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-right .dOne .lt .d-icon {
          font-size: 4vmax;
          color: #000000c1;
}

.TradingDemat-main .TradingDemat-page .trading-services{
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;

}
.TradingDemat-main .TradingDemat-page .trading-services .t-box{
          width: 30%;
          height: 200px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          margin-top: 3vmax;
          padding: 0.5vmax;
          margin-bottom: 3vmax;
          border-radius: 5px;
          box-shadow: 2px 2px 5px 2px rgba(232, 230, 230, 0.779);
}

.TradingDemat-main .TradingDemat-page .trading-services .t-box .anim{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
}
.TradingDemat-main .TradingDemat-page .trading-services .t-box .anim video{
          width: 50%;
          
}
.TradingDemat-main .TradingDemat-page .trading-services .t-box .anim img{
    width: 40%;
    
}
.TradingDemat-main .TradingDemat-page .trading-services .t-box .text{
        font-weight: 400;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
}
.TradingDemat-main .TradingDemat-page .trading-services .t-box .text h6{
    text-align: center;
    font-weight: 400;
    font-family:Arial, Helvetica, sans-serif;
}

/* ------------------------counter------------------------------------------- */

.TradingDemat-main .TradingDemat-page .loan-counter {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 3vmax;
        flex-direction: column;
        border-top: 1px solid #00000026;
        overflow: hidden; /* Ensure images stay within the section */
        z-index: 1; /* Ensure content is above background images */
    }
    
    .TradingDemat-main .TradingDemat-page .loan-counter::before,
    .TradingDemat-main .TradingDemat-page .loan-counter::after {
        content: '';
        position: absolute;
        background-image: url('https://pixydrops.com/insur-html/main-html/assets/images/shapes/testimonial-one-shape-3.png');
        background-size: contain;
        background-repeat: no-repeat;
        width: 50vmax; /* Adjust width as needed */
        height: 80vmax; /* Adjust height as needed */
        opacity: 0.8; /* Slight transparency */
        z-index: 0; /* Ensure background images are behind everything */
        animation: subtleMove 15s ease-in-out infinite;
    }
    
    /* Left side image with a slight tilt */
    .TradingDemat-main .TradingDemat-page .loan-counter::before {
        left: -5vmax; /* Position outside the visible area */
        top: 3vmax;
        transform: rotate(-10deg); /* Slight tilt */
    }
    
    /* Right side image with a slight tilt */
    .TradingDemat-main .TradingDemat-page .loan-counter::after {
        right: -5vmax; /* Position outside the visible area */
        top: -2vmax;
        transform: rotate(10deg); /* Slight tilt */
    }
    
    /* Keyframes for subtle animation */
    @keyframes subtleMove {
        0% {
            transform: translateY(0) rotate(0deg);
        }
        50% {
            transform: translateY(-10px) rotate(10deg);
        }
        100% {
            transform: translateY(0) rotate(0deg);
        }
    }
    
    /* Styling for h6 within the loan counter */
    .TradingDemat-main .TradingDemat-page .loan-counter h6 {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5vmax;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #000;
        margin-bottom: 1vmax;
        z-index: 1; /* Ensure it appears above the background */
    }
    
    /* Flexbox for loan boxes */
    .TradingDemat-main .TradingDemat-page .loan-counter .counter-page {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        margin-top: 1.5vmax;
        margin-bottom: 1vmax;
        z-index: 1; /* Ensure loan boxes appear above the background */
    }
    
    /* Loan box with subtle hover and scale animations */
    .TradingDemat-main .TradingDemat-page .loan-counter .counter-page .loan-box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 22%;
        background-color: #ffffff26;
        padding: 2vmax;
        border-radius: 5px;
        color: #353535a0;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        z-index: 1; /* Ensure boxes are above background */
    }
    
    /* Hover effect on loan box */
    .TradingDemat-main .TradingDemat-page .loan-counter .counter-page .loan-box:hover {
        transform: scale(1.05);
        box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    }
    
    /* Styling for the loan count numbers */
    .TradingDemat-main .TradingDemat-page .loan-counter .counter-page .loan-box span {
        font-size: 3vmax;
        font-weight: 700;
        z-index: 1;
    }
    
    /* Loan title styling */
    .TradingDemat-main .TradingDemat-page .loan-counter .counter-page .loan-box h6 {
        margin-top: 1vmax;
        font-size: 1vmax;
        color: #353535a0;
        z-index: 1;
    }
    
    /* Hover effect for text color change */
    .TradingDemat-main .TradingDemat-page .loan-counter .counter-page .loan-box:hover span,
    .TradingDemat-main .TradingDemat-page .loan-counter .counter-page .loan-box:hover h6 {
        color: #015ec9c8;
    }





    .TradingDemat-main .TradingDemat-page .benefits{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f2f5f9;
    }
    .TradingDemat-main .TradingDemat-page .benefits .beni-fits{
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 2vmax;
    }
    .TradingDemat-main .TradingDemat-page .benefits .beni-fits .benefit-top{
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }
    .TradingDemat-main .TradingDemat-page .benefits .beni-fits .benefit-top h4 small{
       color: #015FC9;
       font-weight: 700;
       /* text-transform: uppercase; */
       font-size: 2vmax
    }
    .TradingDemat-main .TradingDemat-page .benefits .beni-fits .benefit-top span{
        width: 8%;
        margin-bottom: 0.3vmax;
        height: 10px;
        border-radius: 30px;
        background-image: -webkit-linear-gradient(0deg, rgb(1, 95, 201) 0%, rgb(12, 224, 255) 100%);
     }
    

 .TradingDemat-main .TradingDemat-page .benefits .beni-fits   .benefit-btm{
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;  
        margin-top: 2vmax;
        gap: 1vmax;
        margin-bottom: 2vmax;
 }
 .TradingDemat-main .TradingDemat-page .benefits .beni-fits   .benefit-btm .six{
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;  
        padding: 1.2vmax 2vmax;
        background: #fff;
        border-radius: 10px;
        box-shadow: 2px 2px 5px 2px rgb(230, 230, 230);  
 }
 .TradingDemat-main .TradingDemat-page .benefits .beni-fits   .benefit-btm .six h3{
       font-size: 1.2vmax;
       text-transform: uppercase;
       color: #015FC9;
       font-weight: 700;
       letter-spacing: 1px;
       font-family:'Times New Roman', Times, serif;
 }
 .TradingDemat-main .TradingDemat-page .benefits .beni-fits   .benefit-btm .six p{
        font-size: 1.1vmax;
        color: #1f1f1fd2;
        font-family: Arial, Helvetica, sans-serif;
  }
      

@media (max-width:600px) {

          .TradingDemat-main {
           width: 100%;
          }
          .TradingDemat-main .TradingDemat-page {
                    width: 100%;
          }
          .TradingDemat-main .TradingDemat-page .demat-top h3 span {
                    width: 30%;
          }

          .TradingDemat-main .TradingDemat-page .demat-one {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #f2f5f9;
          } 
          .TradingDemat-main .TradingDemat-page .demat-one .demat-content {
                    width: 95%;
                    flex-direction: column;
          }    
          .TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-left {
                    width: 100%;
          }       
          .TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-left span {
                    width: 25%;
                    height: 10px;
                    margin-top: 10vmax;
          }
               .TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-left h5 {
                    font-size: 4vmax;
          }
          .TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-right {
                    width: 95%;
                    margin-top: 3vmax;
          }
          
          .TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-right .dOne {
                    width: 100%;
                    
          }
          
          .TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-right .dOne .rt {
                    width: 75%;
          } 
          .TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-right .dOne .rt p {
                    font-size: 1.5vmax;
          }
          
          .TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-right .dOne .lt {
                    width: 25%;
          }
          
          .TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-right .dOne .lt .d-icon {
                    font-size: 7vmax;
          }

  
.TradingDemat-main .TradingDemat-page .trading-services{
          width: 95%;
          flex-direction: column;

}
.TradingDemat-main .TradingDemat-page .trading-services .t-box{
          width: 95%;
}



        
/* -------------------------------------------------------------------------------------------------------------------- */
          .TradingDemat-main .TradingDemat-page .loan-btm {
                    width: 100%;
          }

          .TradingDemat-main .TradingDemat-page .loan-btm h5 {
                    width: 95%;
                    margin-top: 3vmax;
                    text-align: center;
          }

          
.TradingDemat-main .TradingDemat-page .loan-btm .form-page {
    width: 100%;
    flex-direction: column-reverse;
  }

  .TradingDemat-main .TradingDemat-page .loan-btm .form-page  .form-img{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .TradingDemat-main .TradingDemat-page .loan-btm .form-page  .form-img img{
    width: 80%;
  }

          .TradingDemat-main .TradingDemat-page .loan-btm .form-page form {
                    width: 95%;
                    gap: 3vmax;
                    padding: 2vmax;

          }

          .TradingDemat-main .TradingDemat-page .loan-btm .form-page form .ipt {
                    gap: 0.7vmax;
          }


          .TradingDemat-main .TradingDemat-page .loan-btm .form-page form .ipt input {
                    padding: 2vmax 1vmax;
          }
          .TradingDemat-main .TradingDemat-page .loan-btm .form-page form .ipt button {
                    width: 100%;
                    padding: 1.8vmax 1vmax;
          }


          
/* Disabled button style when loading */
.TradingDemat-main .TradingDemat-page .loan-btm .form-page form .ipt button.loading {
    background-color: #A0C1E8; /* Lighter color to show disabled effect */
    cursor: not-allowed; /* Show a disabled cursor */
    opacity: 0.6; /* Make it look disabled */
  }
  
  /* Loader spinner */
  .loader {
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #3498db;
    width: 15px;
    height: 15px;
    animation: spin 1s linear infinite;
    display: inline-block;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


          
        /* ------------------------------counter--------------------------- */
        .TradingDemat-main .TradingDemat-page .loan-counter {
                width: 100%;
                padding: 3vmax;
            }
            
            .TradingDemat-main .TradingDemat-page .loan-counter h6 {
                width: 95%;
                font-size: 3vmax;
                text-align: center;
            }
            
            .TradingDemat-main .TradingDemat-page .loan-counter .counter-page {
                width: 95%;
                flex-direction: column;
            }
            
            .TradingDemat-main .TradingDemat-page .loan-counter .counter-page .loan-box {
                width: 100%;
                margin-top: 1vmax;
                padding: 2vmax;
            }
            
            /* Adjust font size for loan box span */
            .TradingDemat-main .TradingDemat-page .loan-counter .counter-page .loan-box span {
                font-size: 3vmax;
            }
            
            /* Adjust font size for loan box title */
            .TradingDemat-main .TradingDemat-page .loan-counter .counter-page .loan-box h6 {
                font-size: 2vmax;
            }
            
            /* Additional styling for images to ensure proper responsiveness */
            .TradingDemat-main .TradingDemat-page .loan-counter::before,
            .TradingDemat-main .TradingDemat-page .loan-counter::after {
                content: '';
                position: absolute;
                background-image: url('https://pixydrops.com/insur-html/main-html/assets/images/shapes/testimonial-one-shape-3.png');
                background-size: contain; /* Ensure the image fits within the container */
                background-repeat: no-repeat;
                width: 80vmax; /* Responsive width */
                height: 80vmax; /* Responsive height */
                opacity: 0.8; /* Slight transparency */
                z-index: 0; /* Keep behind content */
                animation: subtleMove 15s ease-in-out infinite;
            }
            
            /* Left side image positioning */
            .TradingDemat-main .TradingDemat-page .loan-counter::before {
                left: -10vmax; /* Responsive left positioning */
                top: 40vmax;
                transform: rotate(-10deg);
            }
            
            /* Right side image positioning */
            .TradingDemat-main .TradingDemat-page .loan-counter::after {
                right: -10vmax; /* Responsive right positioning */
                top:-5vmax;
                transform: rotate(10deg);
            }
   /* -------------------------------------------          */
 
   .TradingDemat-main .TradingDemat-page .benefits{
        width: 100%;
    }
    .TradingDemat-main .TradingDemat-page .benefits .beni-fits{
        width: 95%;
    }
    .TradingDemat-main .TradingDemat-page .benefits .beni-fits .benefit-top{
        width: 95%;
        margin-top: 3vmax;
    }
    .TradingDemat-main .TradingDemat-page .benefits .beni-fits .benefit-top h4 small{
       font-size: 3vmax
    }
    .TradingDemat-main .TradingDemat-page .benefits .beni-fits .benefit-top span{
        width: 20%;
        height: 8px;
     }
    
 .TradingDemat-main .TradingDemat-page .benefits .beni-fits   .benefit-btm{
        gap: 2vmax;
 }
 .TradingDemat-main .TradingDemat-page .benefits .beni-fits   .benefit-btm .six{
        width: 100%; 
        padding: 1.5vmax 2vmax;
 }
 .TradingDemat-main .TradingDemat-page .benefits .beni-fits   .benefit-btm .six h3{
       font-size: 2vmax;
       font-weight: 600;
 }
 .TradingDemat-main .TradingDemat-page .benefits .beni-fits   .benefit-btm .six p{
        font-size: 1.5vmax;
  }
                 
        
}







@media (min-width:600px) and (max-width:1150px) {

        .TradingDemat-main {
         width: 100%;
        }
        .TradingDemat-main .TradingDemat-page {
                  width: 100%;
        }
        .TradingDemat-main .TradingDemat-page .demat-top h3 span {
                  width: 30%;
        }

        .TradingDemat-main .TradingDemat-page .demat-one {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: #f2f5f9;
        
        } 
        .TradingDemat-main .TradingDemat-page .demat-one .demat-content {
                  width: 95%;
                  flex-direction: column;
        }    
        .TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-left {
                  width: 100%;
        }       
        .TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-left span {
                  width: 25%;
                  height: 10px;
                  margin-top: 10vmax;
        }
             .TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-left h5 {
                  font-size: 4vmax;
        }
        .TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-right {
                  width: 95%;
                  margin-top: 3vmax;
        }
        
        .TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-right .dOne {
                  width: 100%;
                  
        }
        
        .TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-right .dOne .rt {
                  width: 75%;
        } 
        .TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-right .dOne .rt p {
                  font-size: 1.5vmax;
        }
        
        .TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-right .dOne .lt {
                  width: 25%;
        }
        
        .TradingDemat-main .TradingDemat-page .demat-one .demat-content .d-right .dOne .lt .d-icon {
                  font-size: 7vmax;
        }


.TradingDemat-main .TradingDemat-page .trading-services{
        width: 95%;
        flex-direction: column;

}
.TradingDemat-main .TradingDemat-page .trading-services .t-box{
        width: 95%;
}



      
/* -------------------------------------------------------------------------------------------------------------------- */
        .TradingDemat-main .TradingDemat-page .loan-btm {
                  width: 100%;
        }

        .TradingDemat-main .TradingDemat-page .loan-btm h5 {
                  width: 95%;
                  margin-top: 3vmax;
                  text-align: center;
        }

                
.TradingDemat-main .TradingDemat-page .loan-btm .form-page {
    width: 100%;
    flex-direction: column-reverse;
  }

  .TradingDemat-main .TradingDemat-page .loan-btm .form-page  .form-img{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .TradingDemat-main .TradingDemat-page .loan-btm .form-page  .form-img img{
    width: 80%;
  }

      

        .TradingDemat-main .TradingDemat-page .loan-btm .form-page form {
                  width: 95%;
                  gap: 3vmax;
                  padding: 2vmax;

        }

        .TradingDemat-main .TradingDemat-page .loan-btm .form-page form .ipt {
                  gap: 0.7vmax;
        }


        .TradingDemat-main .TradingDemat-page .loan-btm .form-page form .ipt input {
                  padding: 2vmax 1vmax;
        }

        .TradingDemat-main .TradingDemat-page .loan-btm .form-page form .ipt button {
                  width: 100%;
                  padding: 1.8vmax 1vmax;
        }
      /* ------------------------------counter--------------------------- */
      .TradingDemat-main .TradingDemat-page .loan-counter {
              width: 100%;
              padding: 3vmax;
          }
          
          .TradingDemat-main .TradingDemat-page .loan-counter h6 {
              width: 95%;
              font-size: 3vmax;
              text-align: center;
          }
          
          .TradingDemat-main .TradingDemat-page .loan-counter .counter-page {
              width: 95%;
              flex-direction: column;
          }
          
          .TradingDemat-main .TradingDemat-page .loan-counter .counter-page .loan-box {
              width: 100%;
              margin-top: 1vmax;
              padding: 2vmax;
          }
          
          /* Adjust font size for loan box span */
          .TradingDemat-main .TradingDemat-page .loan-counter .counter-page .loan-box span {
              font-size: 3vmax;
          }
          
          /* Adjust font size for loan box title */
          .TradingDemat-main .TradingDemat-page .loan-counter .counter-page .loan-box h6 {
              font-size: 2vmax;
          }
          
          /* Additional styling for images to ensure proper responsiveness */
          .TradingDemat-main .TradingDemat-page .loan-counter::before,
          .TradingDemat-main .TradingDemat-page .loan-counter::after {
              content: '';
              position: absolute;
              background-image: url('https://pixydrops.com/insur-html/main-html/assets/images/shapes/testimonial-one-shape-3.png');
              background-size: contain; /* Ensure the image fits within the container */
              background-repeat: no-repeat;
              width: 80vmax; /* Responsive width */
              height: 80vmax; /* Responsive height */
              opacity: 0.8; /* Slight transparency */
              z-index: 0; /* Keep behind content */
              animation: subtleMove 15s ease-in-out infinite;
          }
          
          /* Left side image positioning */
          .TradingDemat-main .TradingDemat-page .loan-counter::before {
              left: -10vmax; /* Responsive left positioning */
              top: 40vmax;
              transform: rotate(-10deg);
          }
          
          /* Right side image positioning */
          .TradingDemat-main .TradingDemat-page .loan-counter::after {
              right: -10vmax; /* Responsive right positioning */
              top:-5vmax;
              transform: rotate(10deg);
          }
 /* -------------------------------------------          */

 .TradingDemat-main .TradingDemat-page .benefits{
      width: 100%;
  }
  .TradingDemat-main .TradingDemat-page .benefits .beni-fits{
      width: 95%;
  }
  .TradingDemat-main .TradingDemat-page .benefits .beni-fits .benefit-top{
      width: 95%;
      margin-top: 3vmax;
  }
  .TradingDemat-main .TradingDemat-page .benefits .beni-fits .benefit-top h4 small{
     font-size: 3vmax
  }
  .TradingDemat-main .TradingDemat-page .benefits .beni-fits .benefit-top span{
      width: 20%;
      height: 8px;
   }
  
.TradingDemat-main .TradingDemat-page .benefits .beni-fits   .benefit-btm{
      gap: 2vmax;
}
.TradingDemat-main .TradingDemat-page .benefits .beni-fits   .benefit-btm .six{
      width: 100%; 
      padding: 1.5vmax 2vmax;
}
.TradingDemat-main .TradingDemat-page .benefits .beni-fits   .benefit-btm .six h3{
     font-size: 2vmax;
     font-weight: 600;
}
.TradingDemat-main .TradingDemat-page .benefits .beni-fits   .benefit-btm .six p{
      font-size: 1.5vmax;
}
               
      
}